import {
  Resource,
  ResourceProfile,
  ResourceProfileCreateBulkDto,
  ResourceProxy,
  ResourceProfileField,
} from '@robogo/common';
import { API } from './api';
import { Http } from './request';

export class APIResource extends API<Resource> {
  constructor() {
    super('resource');
  }
}

export const apiResource = new APIResource();

export class APIResourceProxy extends API<ResourceProxy> {
  constructor() {
    super('resource-proxy');
  }

  createBulk(data: any) {
    return Http.Post<ResourceProxy[]>(`/api/${this.name}/createBulk`, data);
  }
}

export const apiResourceProxy = new APIResourceProxy();

export class APIResourceProfile extends API<ResourceProfile> {
  constructor() {
    super('resource-profile');
  }

  createBulk(data: ResourceProfileCreateBulkDto) {
    return Http.Post<ResourceProfile[]>(`/api/${this.name}/createBulk`, data);
  }

  getFields(resourceId: string) {
    return Http.Get<ResourceProfileField>(`/api/${this.name}/fields/${resourceId}`);
  }
}

export const apiResourceProfile = new APIResourceProfile();
