import { Process } from '@robogo/common';
import { API } from './api';
export class APIProcess extends API<Process> {
  constructor() {
    super('process');
  }
}

export const apiProcess = new APIProcess();

export class apiSaasProcess {
  name = 'saas/process';

  create(processId: string) {
    return Http.Post<any>(`/api/${this.name}/create/${processId}`);
  }

  proxyUpdate(processId: string) {
    return Http.Put<any>(`/api/${this.name}/proxy/${processId}`);
  }

  settingUpdate(processId: string) {
    return Http.Put<any>(`/api/${this.name}/setting/${processId}`);
  }

  start(processId: string) {
    return Http.Get<any>(`/api/${this.name}/start/${processId}`);
  }

  stop(processId: string) {
    return Http.Get<any>(`/api/${this.name}/stop/${processId}`);
  }

  remove(processId: string) {
    return Http.Delete<any>(`/api/${this.name}/remove/${processId}`);
  }

  status(processId: string) {
    return Http.Get<{
      success: boolean;
      status: Record<string, number>;
    }>(`/api/${this.name}/status/${processId}`);
  }
}
export const APISaasProcess = new apiSaasProcess();
