import { Profile, ProfileGroup } from '@robogo/common';
import { API } from './api';
import { Http } from './request';

export class APIProfile extends API<Profile> {
  constructor() {
    super('profile');
  }

  createBulk(data: any) {
    return Http.Post<ProfileGroup>(`/api/${this.name}/createBulk`, data);
  }

  getGroups() {
    return Http.Get<ProfileGroup[]>(`/api/${this.name}/groups`);
  }

  deleteGroup(id: string) {
    return Http.Delete(`/api/${this.name}/groups/${id}`);
  }
}

export const apiProfile = new APIProfile();
