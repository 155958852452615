import { Proxy, ProxyGroup } from '@robogo/common';
import { API } from './api';
import { Http } from './request';

export class APIProxy extends API<Proxy> {
  constructor() {
    super('proxy');
  }

  createBulk(data: any) {
    return Http.Post<ProxyGroup>(`/api/${this.name}/createBulk`, data);
  }

  getGroups() {
    return Http.Get<ProxyGroup[]>(`/api/${this.name}/groups`);
  }

  deleteGroup(id: string) {
    return Http.Delete(`/api/${this.name}/groups/${id}`);
  }
}

export const apiProxy = new APIProxy();
