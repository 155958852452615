export function useLoading() {
  const loading = ref(false);

  function setLoading(value: boolean) {
    loading.value = value;
  }

  function startLoading() {
    setLoading(true);
  }

  function closeLoading() {
    setLoading(false);
  }
  return {
    loading,
    setLoading,
    startLoading,
    closeLoading,
  };
}
