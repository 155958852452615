import { Robot } from '@robogo/common';
import { API } from './api';

export class APIRobot extends API<Robot> {
  constructor() {
    super('robot');
  }
}

export const apiRobot = new APIRobot();
