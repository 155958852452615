import { Http } from './request';
import { APIResponse, MongonDBPager, PageQueryReq } from '@robogo/common';
import * as querystring from 'querystring';
import lodash from 'lodash';

export interface IAPI<T = unknown> {
  findAll(query?: Partial<T> | Record<string, any>): Promise<APIResponse<T[]>>;
  findById(id: string): Promise<APIResponse<T>>;
  pageQuery(query: PageQueryReq & Partial<T> & Record<string, any>): Promise<APIResponse<MongonDBPager<T>>>;
  create(data: Partial<T>): Promise<APIResponse<T>>;
  updateById(id: string, data: Partial<T>): Promise<APIResponse<T>>;
  updataMany(ids: string[], data: Partial<T>): Promise<APIResponse<number>>;
  deleteById(id: string): Promise<APIResponse<T>>;
  updateById(id: string, data: Partial<T>): Promise<APIResponse<T>>;
  deleteMany(ids: string[]): Promise<APIResponse<number>>;
}

export class API<T> implements IAPI<T> {
  public name: string;

  constructor(name: string) {
    this.name = name;
  }

  findAll(query?: Partial<T> | Record<string, any>) {
    const q = lodash.pickBy(query, (v) => !!v);
    const queryStr = querystring.stringify(q);
    return Http.Get<T[]>(`/api/${this.name}/all?${queryStr}`);
  }
  findById(id: string) {
    return Http.Get<T>(`/api/${this.name}/id/${id}`);
  }
  findOne(query?: Partial<T> | Record<string, any>) {
    const q = lodash.pickBy(query, (v) => v !== undefined);
    const queryStr = querystring.stringify(q);
    return Http.Get<T>(`/api/${this.name}/one?${queryStr}`);
  }
  pageQuery(query: PageQueryReq & Partial<T> & Record<string, any>) {
    const q = lodash.pickBy(query, (v) => v !== undefined);
    const queryStr = querystring.stringify(q);
    return Http.Get<MongonDBPager<T>>(`/api/${this.name}/page?${queryStr}`);
  }
  create(data: Partial<T>) {
    return Http.Post<T>(`/api/${this.name}`, data);
  }
  updateById(id: string, data: Partial<T>) {
    return Http.Put<T>(`/api/${this.name}/${id}`, data);
  }
  updataMany(ids: string[], data: Partial<T>) {
    return Http.Post<number>(`/api/${this.name}/updataMany`, { ids, data });
  }
  deleteById(id: string) {
    return Http.Delete<T>(`/api/${this.name}/${id}`);
  }
  deleteMany(ids: string[]) {
    return Http.Post<number>(`/api/${this.name}/deleteMany`, { ids });
  }
}
